import styles from './index.module.css'
import { Link } from 'react-router-dom';

const footer =
`비즈니스 문의 | beenmousetaro@gmail.com
Copyright © 2024 by 콩쥐신녀 All rights reserved, present from AiCodingClub`;

const icons = [
    { to: 'https://instagram.com/beenmousetaro', src: '/images/instagram-icon.png', alt: 'instgram' },
    { to: 'https://www.youtube.com/@beenmouse_taro', src: '/images/youtube-icon.png', alt: 'youtube' },
    { to: 'https://blog.naver.com/beenmousetaro', src: '/images/naver-blog-icon.png', alt: 'naver blog' },
]

function Footer() {
    return (
        <div className={styles.footer}>
            <div className={styles.footerTitle}>{footer}</div>
            <div className={styles.icons}>
                {
                    icons.map(({ to, src, alt }, index) => (
                        <Link key={index} target='_blank' to={to}
                            className={styles.icon}>
                            <img className={styles.image}
                                src={src} alt={alt} />
                        </Link>
                    ))
                }
            </div>
        </div>
    )
}

export default Footer;
import './reset.css'
import './global.css'
import './fonts/fonts.css'
import Header from 'components/header'
import Footer from 'components/footer'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import * as Pages from 'pages'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Pages.Home/>}/>
          <Route path="/reservation" element={<Pages.Reservation/>}/>
          <Route path="/review" element={<Pages.Review/>}/>
          
        </Routes>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;

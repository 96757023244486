import styles from './index.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';
import Animated from 'components/animated';
import { Link } from 'react-router-dom';

const urls = [...Array(10)].map((_, i) => {
  return {
    name: 'review' + (i + 1),
    src: `/images/reviews/review${i + 1}.png`
  }
})

function Review() {
  return (
    <div className={styles.main}>
      <Link to='https://blog.naver.com/beenmousetaro/223643249798' target='_blank'>
        <div className={styles.link}>
          더 많은 상담 후기 (Click)
        </div>
      </Link>
      <Animated>
        <div className={styles.review}>
          <Swiper
            style={{
              "--swiper-pagination-color": "#E39920",
            }}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            autoplay={{
              delay: 30000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}

            className={styles.swiperContainer}>
            {
              urls.map(({ src, name }, index) => (
                <SwiperSlide 
                  className={`${styles.slide} ${index < 4 ? styles.slideDeep : {}}`} 
                  key={index}>
                  <div className={styles.page}>
                    <img
                      src={src}
                      alt={name}
                      className={styles.image} />
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
      </Animated>
    </div>
  );
}

export default Review;

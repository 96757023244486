import styles from './index.module.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/pagination';
import { profile, reviews, reservation } from 'text'
import { Link } from 'react-router-dom';
import Animated from 'components/animated'

function Home() {

    return (
        <div className={styles.home}>
            <div className={styles.topSection}>
                <div className={styles.backgroundOvelay} />
                <div className={styles.textContainer}>
                    <div className={styles.text}>盡人事待天命</div>
                    <div className={styles.textDesc}>{`인생의 길을 찾는 여정\n콩쥐신녀와 함께 시작하세요`}</div>
                </div>
            </div>
            <Animated>
                <div className={styles.sectionTitle}>콩쥐신녀 프로필</div>
                <div className={styles.intro}>
                    <img
                        src={'/images/profile.png'}
                        className={styles.profileImage}
                        alt='profile' />
                    <div className={styles.profileTextBumper} />
                    <div className={styles.profileText}>
                        {profile}
                    </div>
                    <div className={styles.profileTextBumper} />
                </div>
            </Animated>
            <Animated>

                <div className={styles.sectionTitle}>
                    <Link to='/reservation' className={styles.link} >
                        상담예약
                    </Link>
                </div>

                <div className={styles.reservation}>
                    <div className={styles.reservationPage}>
                        <div className={styles.reservationTitle}>이메일 상담</div>
                        <div className={styles.message}>{reservation.email}</div>
                    </div>
                    <div className={styles.reservationPage}>
                        <div className={styles.reservationTitle}>실시간 보이스톡 상담</div>
                        <div className={styles.message}>{reservation.call}</div>
                    </div>
                    <div className={styles.reservationPage}>
                        <div className={styles.reservationTitle}>특별 상담 (별도 문의)</div>
                        <div className={styles.message}>{reservation.special}</div>
                    </div>
                </div>
            </Animated>
            <Animated>
                <div className={styles.sectionTitle}>
                    <Link to='/review' className={styles.link} >
                        상담후기
                    </Link>
                </div>

                <div className={styles.review}>
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "#E39920",
                        }}
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Autoplay, Pagination]}
                        className={styles.swiperContainer}>
                        {
                            reviews.map((text, index) => (
                                <SwiperSlide className={styles.slide} key={index}>
                                    <div className={styles.page}>
                                        <img
                                            src={`/images/review_photo${index + 1}.png`}
                                            alt={`review_photo${index + 1}`}
                                            className={styles.reviewPhoto} />
                                        {text}
                                    </div>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </Animated>
        </div>
    );
}

export default Home;

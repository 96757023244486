import styles from './index.module.css';
import { 
  warning, 
  requestWarning, 
  requestWarning2,  
  etcWarning1,
  etcWarning2,
} from 'text'
import { Link } from 'react-router-dom';
import Animated from 'components/animated'

function Reservation() {
  return (
    <div className={styles.main}>
      <div className={styles.imageConatiner}>
        <img
          className={styles.image}
          src='/images/reservation.png'
          alt='reservation' />
      </div>
      <Link to='https://blog.naver.com/beenmousetaro/223479989170' target='_blank'>
        <div className={styles.link}>
          자세한 상담 안내문 (Click)
        </div>
      </Link>
      <Animated>
        <div className={styles.sectionTitle}>필독사항</div>
        <div className={styles.warning}>
          {warning}
        </div>
      </Animated>
      <Animated>
        <div className={styles.sectionTitle}>상담신청 주의사항</div>
        <div className={`${styles.warning} ${styles.warning2}`}>
          {requestWarning}
          <div className={styles.warningFocus}>
            {requestWarning2}
          </div>
          <div className={styles.example}>
            <div className={styles.exampleImageContainer}>
              올바른 예시
              <img
                alt='request-example'
                className={styles.requestImage}
                src='/images/request-example.jpg' />
            </div>
          </div>
        </div>
      </Animated>
      <Animated>
        <div className={styles.sectionTitle}>기타 안내사항</div>
        <div className={`${styles.warning} ${styles.warning3}`}>
          <div className={styles.warningFocus}>{etcWarning1}</div>
          <div className={styles.warningBlur}>{etcWarning2}</div>
        </div>
      </Animated>
    </div>
  );
}

export default Reservation;

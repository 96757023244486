import { useEffect, useState, useRef } from 'react';
import styles from './index.module.css'
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { scrollToTop } from 'util/index'

const Menu = ({ to, name, target = '', onClick = () => { }, style = {} }) => {

  return (
    <Link onClick={onClick} className={styles.link} to={to} target={target}>
      <div className={`${styles.menuItem} ${style}`}>{name}</div>
    </Link>
  )
}


const menu = [
  { to: '/', name: '홈' },
  { to: '/reservation', name: '상담예약' },
  { to: '/review', name: '상담후기' },
  { to: 'https://www.youtube.com/@beenmouse_taro', target: '_blank', name: '유튜브' },
  { to: 'https://blog.naver.com/beenmousetaro', target: '_blank', name: '블로그' },
  { to: 'mailto:beenmousetaro@gmail.com', target: '_blank', name: '비즈니스 문의' },
]


function Header() {

  const [show, setShow] = useState(false);
  const location = useLocation();
  const [pathName, setPathName] = useState('/');

  const ref = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setPathName(location.pathname);
    scrollToTop();
  }, [location]);

  const onClickMenuIcon = () => {
    setShow(!show);
  }

  const hideMenu = () => {
    setShow(false);
  }

  return (
    <div ref={ref} className={styles.headerContainer}>
      <div className={styles.headerEmpty} />
      <div className={styles.header}>
        <Link to="/" className={styles.titleSection}>
          <img
            className={styles.image}
            src={'/images/logo.png'}
            alt='icon'
          />
          <div className={styles.title}>콩쥐신녀</div>
        </Link>

        <div className={styles.side}>
          <div onClick={onClickMenuIcon} className={styles.menuIcon}>
            <MenuIcon />
          </div>
          <div className={`${styles.menuList} ${show ? styles.show : styles.hide}`}>
            <div className={styles.menu}>
              {
                menu.map(({ to, name, target }, index) => (
                  <Menu
                    key={index}
                    style={pathName === to ? styles.selected : {}}
                    onClick={hideMenu}
                    to={to}
                    name={name}
                    target={target} />
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;

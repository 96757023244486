import { useEffect, useRef, useState } from 'react';
import { checkIsInViewport } from 'util/index';
import styles from './index.module.css'

function Animated ({ children, className={} }) {
    const [show, setShow] = useState(false);
    const areaRef = useRef();

    const handleScrollAnimation = () => {
        const elementTop = areaRef?.current;
        if(!show) {
            setShow(checkIsInViewport(elementTop));
        }
    }

    useEffect(() => {
        handleScrollAnimation();
        window.addEventListener('scroll', handleScrollAnimation);

        return () => {
            window.removeEventListener('scroll', handleScrollAnimation);
        };
    }, [show, areaRef.current]);

    return (
        <div ref={areaRef} 
            className={`${className} ${styles.animated} ${show ? styles.show : {}}`}>
            {children}
        </div>
    )
}

export default Animated;
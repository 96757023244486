const profile =
    `- 서울예술대학교 졸업


- 유투브 <콩쥐신녀> 타로 채널 운영


- 상담 심리, 인간 심리, 색채, 사주 명리, 

대인관계 상담 전문 자격


- 타로 심리 상담사 1급


- 前 C, H, M 국내 최상위 운세 플랫폼 

1티어 초빙 멘탈리스트


- 재상담 多 4000회 이상의 

풍부한 현장 경험 영매사


- 다수의 연예인, 인플루언서 

단골 내담자 보유


- 기업/엔터 경영, 소속 아티스트 

이미지 컨설팅 전문`;

const reviews = [
    `묵은 체증이 싹 가시네요..
전화 받고부터 절 다 아시듯 말해서
깜짝 놀랐는데 진짜 ㅠㅠ
쌤 탑오브탑이시네요
오늘 진짜 감사드립니다
고민이 해결됬어요.. 감사합니다`,

    `오늘 너무 감사했어요.
방향성이 구체적으로 보이네요.
콩쥐신녀님과 얘기나누면 맘이 
편해지는 이유는 뭘까요?
바쁘신데 건강 잘 돌보면서 일하세요~`,

    `아이구 콩쥐님 진짜 감사합니다..
모두 저에게 힘이 되는 메시지들뿐이어서
한 자 한 자 소중하게 읽어 내려가며 되새겼습니다.
심지어 이 모든 내용이 콩쥐님께서 해주신 말씀이라니
든든한 제편 하나 생긴것 같아서 저도 모르게 울컥했네요..
살면서 뜻대로 안 흘러 갈 때도 많았지만
저에 대한 믿음 잃지 않고 살아온 것에 대한 보상인 것 같습니다.
힘들때마다 읽으며 마음 다잡고 정진하고자 합니다.
그럼 또 연락드리겠습니다. 부디 평안하십시오 🙇‍♀️`,

    `유튜브도 항상 감사히 잘 보고 있습니다!!
저에겐 많이 위로되는 영상이라 
멤버십도 신청하게 되더라구요ㅎㅎ
올려주시는 유튜브 영상보면서
기다리고 있겠습니다~!!`,
]

const reservation = {
    email:
        `회당 5만원
궁금하신 질문 3가지에 대한 답변
간단하고 명확한 질의응답
(빠른 상담 65,000원)`,

    call:
        `30분 10만원
1시간 15만원
구체적이고 심층적인 질의응답
(빠른상담 추가금 5만원)`,

    special:
        `실시간 상담 대기를 최소한으로 잡아드려요.
상담료에 차등이 있습니다.`
}

const reservationInfo = {
    email:
        `회당 5만원입니다.

궁금하신 질문 3가지 가능하며, 질문이 1~2가지일 경우 더욱 상세한 답변을 받아보실수 있습니다.

일주일 안에 이메일로 전달해 드리나 예약이 밀릴경우 2-3주 정도 대기가 필요합니다.`,

    call:
        `실시간 상담은 30분 10만원, 1시간 15만원이며

기본 보이스톡 통화로 진행하오나 통화가 어려우신 분에 한해 채팅으로 진행 가능합니다.`
}

const warning =
    `● 상담시에는 성함, 생년월일이 필요합니다. (본인이 아닌 상대방의 경우엔 성함, 나이까지만 알아도 괜찮습니다) 

● 저는 눈을 보면 그 사람의 과거, 현재, 미래를 볼 수 있습니다. 때문에 가능하신 분은 외모 사진도 함께 보내주시면 좋습니다. (사진이 꼭 있어야 점을 볼 수 있는 건 아니니 부담 갖지 않으셔도 됩니다.)

● 저는 영매사입니다. 타로는 제가 오랫동안 해왔기에 보조로 봐드리는 것일뿐 대부분은 제가 내담자님께 느껴지는 것들을 위주로 말씀드린다고 생각하시면 됩니다. (저의 영적인 기운을 활용하기에 일반 사주 상담, 타로 상담과는 다소 차이가 있습니다.)

● 실시간 상담은 기본 1달 이상 대기가 있으십니다. 급한 용무인 경우 빠른 상담/특별 상담으로 신청 가능하시나 보통은 대기가 있음을 고려하여 문의주시면 됩니다.

● 상담비는 선입금입니다. 예약 가능한 날짜는 순차적으로 안내드리고 있기 때문에, 소통이 느리거나 입금 절차가 제대로 이뤄지지 않으면 다른 분께로 순서가 넘어갑니다. 

● 예약하신 상담 시간 기준으로(30분, 1시간) 다음 상담을 잡기에 보통은 연장이 어려우십니다. 순차적으로 다시 재예약을 잡으셔야 하며, 다른 상담이 끝나고 나서라도 당일날 이어서 통화하길 바라실 경우 상담비에 차등이 생길 수 있습니다.
`

const requestWarning =
    `신청할 상담 시간(방식)과, 어떤 고민으로 상담 받으려 하시는지 간략히 사연을 말씀해주시면 됩니다. 분야와 시간에 따라 예약 가능한 날짜에 차등이 있기에 꼭 양식을 맞춰서 문의 메시지 보내주세요.`

const requestWarning2 =
    `
'안녕하세요' '상담 되나요?' '상담하고 싶습니다' '언제쯤 가능한가요?'만 보내시면 답장을 드리기가 어렵습니다.`

const etcWarning1 =
    `※미성년자분들은 집에 우환이 있지 않은 이상 점을 안 보시는 걸 추천드립니다. 아주 간혹 학업 문제만 봐드렸는데, 이제는 성인분들만 신청 받습니다.(부모님이 아이에 관해ㅡ 성인이 미성년자에 대해ㅡ 질문하시는 건 가능합니다.)※

※한 번 약속이 깨지거나, 대화 도중에 답장 끊기신 분은 상담 신청받지 않습니다. 점사는 인연합에서 오는 신뢰가 가장 중요하며 이미 사대가 어긋난 분에게는 시간 쓰지 않는 것이 제 철칙입니다.※

※가장 자신 있는 분야는 진로, 직업 / 애정, 결혼(궁합) / 가정 문제(자녀, 배우자 등)입니다. 사주풀이식의 추상적인 궁금증ㅡ EX) "전 부자가 될까요?" "제 인생은 운이 좋은가요?" "제 수명은 언제까지일까요?"ㅡ 보다는 현재 뚜렷한 고민 상황을 겪고 계신 분들께 제 상담을 추천드려요.※`

const etcWarning2 =
    `
✅️ 외국인에 관한 상담이거나(단순 외국에 거주하는 것은 상관X) 가족의 종합적인 운을 보시려는 경우에는 전화 상담만 가능하며 2인까지 1시간 상담으로 가능하십니다. (그 이상의 인원은 상담 금액이 변동될 수 있습니다.)

✅️ 임신, 건강, 빙의(퇴마)는 직접 보지 않고는 상담이 어려운 부분이기에 신청 받지 않습니다. 신가물인지 아닌지는 사진, 목소리로 어느 정도 판별 가능하지만 절대적이진 않아요. (본인이 이 문제로 상담 받길 원하신다면 블로그에 '신가물' , '귀문관살' 검색해서 먼저 게시물 읽어보세요.)`


export {
    profile,
    reviews,
    reservation,
    reservationInfo,
    warning,
    requestWarning,
    requestWarning2,
    etcWarning1,
    etcWarning2,
}